export enum LoadingState {
    Loading,
    Loaded,
    Error,
}

export enum LoadingState2 {
    Rejected = 'rejected',
    Resolved = 'resolved',
    Pending = 'pending',
    Idle = 'idle',
}

// const isLoading = status === 'idle' || status === 'pending'
// const isResolved = status === 'resolved'
// const isRejected = status === 'rejected'
