import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import storage from 'local-storage-fallback'

export const useAccessToken = (publicAccess = false) => {
    const [token, setToken] = useState<string>('')
    const { getAccessTokenSilently } = useAuth0()

    const getToken = async () => {
        if (typeof window === 'undefined') {
            return false
        }
        try {
            //TODO: refactor this to use something more secure than a hardcoded token
            if (publicAccess) {
                const token = '1234' //just added this to test the public access
                setToken(token)
                storage.setItem('token', token)
                return token
            }

            const token = await getAccessTokenSilently()
            setToken(token)
            storage.setItem('token', token)
            return token
        } catch (error) {
            console.log('🚀 ~ error', error)
        }
    }

    useEffect(() => {
        getToken()
    }, [])

    return [token]
}
