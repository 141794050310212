import * as React from 'react'
import { SearchProvider } from 'usecases/notUsed/Search/context/searchContext'
import { UserProvider } from 'modules/user/context/userContext'
import { ErrorBoundary } from 'react-error-boundary'
import { SWRConfig } from 'swr'
import { toast } from 'react-toastify'
import ErrorFallback from 'shared/components/errorFallback'

const errorHandler = (error: Error, info: { componentStack: string }) => {
    console.log('logging error', error, info)
}

function AppProviders({ children }: { children: JSX.Element | JSX.Element[] }) {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
            <UserProvider>
                <SearchProvider>
                    <SWRConfig
                        value={{
                            onError: (error) => {
                                toast.dark('👋' + error, {
                                    position: 'bottom-right',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                })

                                if (error.status !== 403 && error.status !== 404) {
                                    // We can send the error to Sentry,
                                    // or show a notification UI.
                                }
                            },
                        }}
                    >
                        {children}
                    </SWRConfig>
                </SearchProvider>
            </UserProvider>
        </ErrorBoundary>
    )
}

export default AppProviders
