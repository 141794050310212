let domain;
if (typeof window !== 'undefined') {
    domain = `${window.location.protocol}//${window.location.hostname}`;
    if (window.location.port) {
        domain += `:${window.location.port}`;
    }
}
else {
    domain = 'http://localhost:3001';
}
const ENV_VARIABLES_MAP = {
    apiUrl: process.env.NEXT_PUBLIC_API_URL,
    ironSecretToken: process.env.NEXT_PUBLIC_IRON_SECRET_TOKEN,
    magicSecretKey: process.env.NEXT_PUBLIC_MAGIC_SECRET_KEY,
    magicPublicKey: process.env.NEXT_PUBLIC_MAGIC_PUBLIC_KEY,
    algoliaIndexPrefix: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX,
    auth0: {
        secret: process.env.NEXT_PUBLIC_AUTH0_SECRET,
        baseUrl: process.env.NEXT_PUBLIC_AUTH0_BASE_URL,
        issuerBaseUrl: process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL,
        clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
        clientSecret: process.env.NEXT_PUBLIC_AUTH0_CLIENT_SECRET,
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        redirectUri: domain + process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
        domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    },
};
export const getEnvironmentVariable = (key) => {
    const environmentVariable = ENV_VARIABLES_MAP[key];
    if (!environmentVariable) {
        throw new Error(`Couldn't find environment variable: ${key}`);
    }
    else {
        return environmentVariable;
    }
};
