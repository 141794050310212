import { useAccessToken } from './useAccessToken'
import { useEffect, useState } from 'react'
import useRequest from './useRequest'
import storage from 'local-storage-fallback'
import { getEnvironmentVariable } from 'shared/utils/getEnvironmentVariable'
import { useSWRConfig } from 'swr'

function useRequestEndpoint(endpoint: string): [any, () => void] {
    const [data, setData] = useState()
    const { mutate } = useSWRConfig()
    const isPublic = endpoint.includes('public')
    const [token] = useAccessToken(isPublic)

    const [fetchedData] = useRequest(`${getEnvironmentVariable('apiUrl')}${endpoint}`, token)

    const refreshData = async () => {
        await mutate([`${getEnvironmentVariable('apiUrl')}${endpoint}`, token])
    }

    useEffect(() => {
        setData(fetchedData)
    }, [fetchedData])

    return [data, refreshData]
}

export default useRequestEndpoint
