interface IProps {
    error: any
    resetErrorBoundary: any
}

const ErrorFallback = ({ error, resetErrorBoundary }: IProps) => {
    return (
        <div>
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}

export default ErrorFallback
