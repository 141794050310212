import 'shared/styles/main.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import type { AppProps } from 'next/app'
import { ToastContainer } from 'react-toastify'
import AppProviders from 'shared/context/AppProviders'
import AuthenticatedApp from 'usecases/auth/components/AuthenticatedApp'
import { useRouter } from 'next/router'
import { Auth0Provider } from '@auth0/auth0-react'
import { getEnvironmentVariable } from 'shared/utils/getEnvironmentVariable'

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter()
    const auth0Variables = getEnvironmentVariable('auth0')

    return (
        <>
            <Auth0Provider
                domain={auth0Variables.domain}
                clientId={auth0Variables.clientId}
                audience={auth0Variables.audience}
                redirectUri={auth0Variables.redirectUri}
                useRefreshTokens={true}
                cacheLocation="localstorage"
            >
                <AppProviders>
                    <AuthenticatedApp>
                        <Component {...pageProps} key={router.asPath} />
                    </AuthenticatedApp>

                    <ToastContainer />
                </AppProviders>
            </Auth0Provider>
        </>
    )
}
export default MyApp
