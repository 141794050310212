//TODO: : https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
import useSWR from 'swr'
import { LoadingState } from 'shared/types/loadingState'

const fetcher = async (url: string | null, token: any = '') => {
    //sleep(2000)
    if (!url || url.includes('undefined') || !token) return null

    const headers = { 'Content-Type': 'application/json' }
    const config = {
        method: 'GET',
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await fetch(url, config)

    if (!response.ok) {
        console.log('error')
        const error = new Error('An error occurred while fetching the data.')
        await response.json()
        throw error
    }

    return await response.json()
}
//<T>: DataResponse<T> //<DataPayload<T>>
function useRequest(url: string | null, token: string | null = '') {
    const key = url ? [url, token] : null
    const { data, error, mutate } = useSWR(key, fetcher)

    const isLoading = !data && !error
    const isError = !!error

    const state = isLoading
        ? LoadingState.Loading
        : isError
        ? LoadingState.Error
        : LoadingState.Loaded

    return [data, state, mutate]
}

export default useRequest
