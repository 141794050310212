import UnauthenticatedApp from './UnauthenticatedApp'
import { useAuth0 } from '@auth0/auth0-react'
import Router, { useRouter } from 'next/router'

const PUBLIC_PAGES = ['/suppliers/[id]/backlog', '/login', '/auth/callback', '/purchase-orders/[id]/public']

const AuthenticatedApp = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const { pathname } = useRouter()
    const { isAuthenticated, isLoading } = useAuth0()
    const isPublicPage = PUBLIC_PAGES.includes(pathname)

    // return <div>{children}</div>
    if (isLoading) return <div>...</div>

    return isAuthenticated || isPublicPage ? <div>{children}</div> : <UnauthenticatedApp />
}

export default AuthenticatedApp
