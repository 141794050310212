import Router, { useRouter } from 'next/router'
import Link from 'shared/components/Link'

const UnauthenticatedApp = () => {
    return (
        <div
            className="is-flex is-justify-content-center is-align-items-center"
            style={{ width: '100%', height: '100vh' }}
        >
            <div>
                No access. Go to <Link href="/login">login</Link>
            </div>
        </div>
    )
}

export default UnauthenticatedApp
