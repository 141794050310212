import { useContext, createContext, useState } from 'react'

type UpdateSearchTerm = (value: string) => void

const SearchContext = createContext<
    | { searchTerm: string | undefined; updateSearchTerm: UpdateSearchTerm }
    | undefined
>(undefined)

const SearchProvider = ({
    children,
}: {
    children: JSX.Element | JSX.Element[]
}) => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>()

    const updateSearchTerm = (value: string) => {
        setSearchTerm(value)
    }

    const value = { searchTerm, updateSearchTerm }

    return (
        <SearchContext.Provider value={value}>
            {children}
        </SearchContext.Provider>
    )
}

function useSearch() {
    const context = useContext(SearchContext)
    if (context === undefined) {
        throw new Error('useCount must be used within a CountProvider')
    }
    return context
}

export { SearchProvider, useSearch }
