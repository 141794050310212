import NextLink from 'next/link'

interface LinkProps {
    href: string
    children: JSX.Element | JSX.Element[]
    className?: string
}

const Link = ({ href, children, ...props }: LinkProps) => {
    return (
        <NextLink href={href} {...props}>
            {children}
        </NextLink>
    )
}

export default Link
