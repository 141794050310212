import { useContext, createContext, useState, useEffect, useMemo } from 'react'
// import Router, { useRouter } from 'next/router'
// import useRequest from 'shared/hooks/useRequest'
// import useRequestEndpoint from 'shared/hooks/useRequestEndpoint'
import { useAuth0 } from '@auth0/auth0-react'
import useRequestEndpoint from 'shared/hooks/useRequestEndpoint'

const UserContext = createContext<{ user: any | undefined } | undefined>(undefined)

const UserProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const [user1, setUser1] = useState()
    // const [data, state] = useRequest('/api/user')
    // const { pathname } = useRouter()
    // const [userData] = useRequestEndpoint(`/me`)
    const { isLoading, isAuthenticated, error, user } = useAuth0()
    const [userInfo] = useRequestEndpoint('/me') // Assuming this returns [data, state]
    // const userInfo = null

    useEffect(() => {
        if (isLoading || (error && !user)) {
            return
        }

        setUser1(() => {
            return {
                ...user,
                company: userInfo?.company,
                name: user?.name,
                phone: user?.phone,
                initials: 'NB',
                image: user?.image,
                role: 'VIEWER',
            }
        })
    }, [user, isLoading, userInfo])

    const value = useMemo(() => ({ user: user1 }), [user1])

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

function useUser() {
    const context = useContext(UserContext)
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvicer')
    }
    return context
}

export { UserProvider, useUser }
